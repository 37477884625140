import { StyleSheet } from "react-native";

export const primaryColor = "#133cb4";
export const secondaryColor = "#c4ebc8";
export const darkerSecondaryColor = "#8bd893";
export const errorColor = "#af0120";
export const lightText = "#666";
export const baseButtonBackground = "#e4e7eb";
export const disabledBackground = "#f3f4f6";
export const pressedBackground = "#dddddd";
export const backButtonBackground = "#fca5a5";
export const backButtonPressedBackground = "#ca8282";

const counterButton = StyleSheet.create({
    base: {
        flex: 1,
        padding: 10,
        margin: 5,
        borderRadius: 10,
        textAlign: "center",
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none"
    },
    elevated: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
});

const cricketButton = StyleSheet.create({
    base: {
        flex: 3,
        padding: 10,
        margin: 15,
        borderRadius: 10,
        textAlign: "center",
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none"
    },
    elevated: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
});

export const styles = StyleSheet.create({
    mainPage: {
        marginTop: 20,
        marginBottom: 20,
        marginLeft: 15,
        marginRight: 15,
    },
    headLine1: {
        fontSize: 20,
        fontWeight: "bold",
        marginBottom: 10,
    },
    headLine2: {
        fontSize: 18,
        fontWeight: "bold",
        marginBottom: 10,
    },
    listPlayerName: {
        fontSize: 17,
        fontWeight: "bold",
        flex: 3,
    },
    listPlayerScore: {
        fontSize: 17,
        flex: 3,
    },
    formWrapper: {
        marginTop: 10,
        marginBottom: 10,
    },
    formDivider: {
        marginTop: 10,
        marginBottom: 10,
    },
    selectBoxLabel: {
        fontSize: 14,
        textAlign: "center",
        color: lightText,
        marginBottom: 5,
    },
    primaryButton: {
        width: 180,
    },
    primaryButtonContainer: {
        marginTop: 10,
        alignSelf: "flex-end",
    },
    playerCard: {
        marginTop: 12,
        marginBottom: 12,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 14,
        paddingRight: 14,
        backgroundColor: "#eee",
    },
    playerCardRow: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 10,
    },
    playerCardName: {
        fontSize: 19,
        fontWeight: "bold",
        marginBottom: 5,
        alignSelf: "flex-start"
    },
    counterButton: {
        ...counterButton.base,
        ...counterButton.elevated,
        backgroundColor: baseButtonBackground,
    },
    counterButtonDisabled: {
        ...counterButton.base,
        backgroundColor: disabledBackground,
    },
    counterButtonPressed: {
        ...counterButton.base,
        backgroundColor: pressedBackground,
    },
    cricketButton: {
        ...cricketButton.base,
        ...cricketButton.elevated,
        backgroundColor: baseButtonBackground,
    },
    cricketButtonDisabled: {
        ...cricketButton.base,
        backgroundColor: disabledBackground,
    },
    cricketButtonPressed: {
        ...cricketButton.base,
        backgroundColor: pressedBackground,
    },
    cricketBackButton: {
        ...cricketButton.base,
        ...cricketButton.elevated,
        backgroundColor: backButtonBackground,
    },
    cricketBackButtonPressed: {
        ...cricketButton.base,
        backgroundColor: backButtonBackground,
    },
    missButton: {
        ...counterButton.base,
        ...counterButton.elevated,
        backgroundColor: baseButtonBackground,
    },
    missButtonPressed: {
        ...counterButton.base,
        backgroundColor: pressedBackground,
    },
    backButton: {
        ...counterButton.base,
        ...counterButton.elevated,
        backgroundColor: backButtonBackground,
    },
    backButtonDisabled: {
        ...counterButton.base,
        backgroundColor: disabledBackground,
    },
    backButtonPressed: {
        ...counterButton.base,
        backgroundColor: backButtonPressedBackground,
    },
    customButton: {
        ...counterButton.base,
        ...counterButton.elevated,
        backgroundColor: secondaryColor,
    },
    customButtonDisabled: {
        ...counterButton.base,
        ...counterButton.elevated,
        backgroundColor: disabledBackground,
    },
    customButtonPressed: {
        ...counterButton.base,
        backgroundColor:darkerSecondaryColor,
    },
    paragraph: {
        fontSize: 16,
        marginBottom: 10,
    },
});