import { Checkout } from "../../types";

export class OutCalculator
{
  private readonly threeDartsCheckouts: Checkout;
  private readonly twoDartsCheckouts: Checkout;
  private readonly oneDartCheckouts: Checkout;

  constructor() {
    this.threeDartsCheckouts = {
      170 : "t20 t20 d25",
      167 : "t20 t19 d25",
      164 : "t19 t19 d25",
      161 : "t20 t17 d25",
      160 : "t20 t20 d20",
      158 : "t20 t20 d19",
      157 : "t20 t19 d20",
      156 : "t20 t20 d18",
      155 : "t20 t19 d19",
      154 : "t19 t19 d20",
      153 : "t20 t19 d18",
      152 : "t20 t20 d16",
      151 : "t20 t17 d20",
      150 : "t19 t19 d18",
      149 : "t20 t19 d16",
      148 : "t20 t20 d14",
      147 : "t20 t17 d18",
      146 : "t19 t19 d16",
      145 : "t20 t15 d20",
      144 : "t20 t20 d12",
      143 : "t20 t17 d16",
      142 : "t20 t14 d20",
      141 : "t20 t19 d12",
      140 : "t20 t20 d10",
      139 : "t19 t14 d20",
      138 : "t20 t18 d12",
      137 : "t20 t19 d10",
      136 : "t20 t16 d14",
      135 : "d25 t15 d20",
      134 : "t20 t14 d16",
      133 : "t20 t19 d8",
      132 : "t20 t16 d12",
      131 : "t19 t14 d16",
      130 : "t20 t20 d5",
      129 : "t19 t16 d12",
      128 : "t18 t14 d16",
      127 : "t20 t17 d8",
      126 : "t19 t19 d6",
      125 : "25 t20 d20",
      124 : "t20 t14 d11",
      123 : "t19 t16 d9",
      122 : "t18 t18 d7",
      121 : "t20 t11 d14",
      120 : "t20 20 d20",
      119 : "t19 t12 d13",
      118 : "t20 18 d20",
      117 : "t20 17 d20",
      116 : "t19 19 d20",
      115 : "t19 18 d20",
      114 : "t20 14 d20",
      113 : "t19 16 d20",
      112 : "t20 12 d20",
      111 : "t19 14 d20",
      110 : "t20 10 d20",
      109 : "t20 9 d20",
      108 : "t20 8 d20",
      107 : "t19 10 d20",
      106 : "t20 6 d20",
      105 : "t19 8 d20",
      104 : "t16 16 d20",
      103 : "t19 6 d20",
      102 : "t16 14 d20",
      101 : "t20 9 d16",
      100 : "t20 d20",
      99 : "t19 10 d16",
      98 : "t20 d19",
      97 : "t19 d20",
      96 : "t20 d18",
      95 : "t19 d19",
      94 : "t18 d20",
      93 : "t19 d18",
      92 : "t20 d16",
      91 : "t17 d20",
      90 : "t20 d15",
      89 : "t19 d16",
      88 : "t20 d14",
      87 : "t17 d18",
      86 : "t18 d16",
      85 : "t15 d20",
      84 : "t20 d12",
      83 : "t17 d16",
      82 : "t14 d20",
      81 : "t19 d12",
      80 : "t20 d10",
      79 : "t19 d11",
      78 : "t18 d12",
      77 : "t19 d10",
      76 : "t16 d14",
      75 : "t17 d12",
      74 : "t14 d16",
      73 : "t19 d8",
      72 : "t16 d12",
      71 : "t13 d16",
      70 : "t18 d8",
      69 : "t19 d6",
      68 : "t12 d16",
      67 : "t9 d20",
      66 : "t10 d18",
      65 : "25 d20",
      64 : "t16 d8",
      63 : "t17 d6",
      62 : "t10 d16",
      61 : "25 d18",
      60 : "20 d20",
      59 : "19 d20",
      58 : "18 d20",
      57 : "17 d20",
      56 : "18 d19",
      55 : "17 d19",
      54 : "16 d19",
      53 : "15 d19",
      52 : "14 d19",
      51 : "13 d19",
      50 : "d25",
      49 : "11 d19",
      48 : "10 d19",
      47 : "9 d19",
      46 : "8 d19",
      45 : "7 d19",
      44 : "6 d19",
      43 : "5 d19",
      42 : "4 d19",
      41 : "3 d19",
      40 : "d20",
      39 : "1 d19",
      38 : "d19",
      37 : "1 d18",
      36 : "d18",
      35 : "1 d17",
      34 : "d17",
      33 : "1 d16",
      32 : "d16",
      31 : "1 d15",
      30 : "d15",
      29 : "13 d8",
      28 : "d14",
      27 : "11 d8",
      26 : "d13",
      25 : "9 d8",
      24 : "d12",
      23 : "7 d8",
      22 : "d11",
      21 : "13 d4",
      20 : "d10",
      19 : "11 d4",
      18 : "d9",
      17 : "9 d4",
      16 : "d8",
      15 : "7 d4",
      14 : "d7",
      13 : "5 d4",
      12 : "d6",
      11 : "3 d4",
      10 : "d5",
      9 : "1 d4",
      8 : "d4",
      7 : "3 d2",
      6 : "d3",
      5 : "1 d2",
      4 : "d2",
      3 : "1 d1",
      2 : "d1"
    }
    this.twoDartsCheckouts = {
      110 : "t20 d25",
      107 : "t19 d25",
      104 : "t18 d25",
      101 : "t17 d25",
      100 : "t20 d20",
      98 : "t20 d19",
      97 : "t19 d20",
      96 : "t20 d18",
      95 : "t19 d19",
      94 : "t18 d20",
      93 : "t19 d18",
      92 : "t20 d16",
      91 : "t17 d20",
      90 : "t20 d15",
      89 : "t19 d16",
      88 : "t20 d14",
      87 : "t17 d18",
      86 : "t18 d16",
      85 : "t15 d20",
      84 : "t20 d12",
      83 : "t17 d16",
      82 : "t14 d20",
      81 : "t19 d12",
      80 : "t20 d10",
      79 : "t19 d11",
      78 : "t18 d12",
      77 : "t19 d10",
      76 : "t16 d14",
      75 : "t17 d12",
      74 : "t14 d16",
      73 : "t19 d8",
      72 : "t16 d12",
      71 : "t13 d16",
      70 : "t18 d8",
      69 : "t19 d6",
      68 : "t12 d16",
      67 : "t9 d20",
      66 : "t10 d18",
      65 : "25 d20",
      64 : "t16 d8",
      63 : "t17 d6",
      62 : "t10 d16",
      61 : "25 d18",
      60 : "20 d20",
      59 : "19 d20",
      58 : "18 d20",
      57 : "17 d20",
      56 : "18 d19",
      55 : "17 d19",
      54 : "16 d19",
      53 : "15 d19",
      52 : "14 d19",
      51 : "13 d19",
      50 : "d25",
      49 : "11 d19",
      48 : "10 d19",
      47 : "9 d19",
      46 : "8 d19",
      45 : "7 d19",
      44 : "6 d19",
      43 : "5 d19",
      42 : "4 d19",
      41 : "3 d19",
      40 : "d20",
      39 : "1 d19",
      38 : "d19",
      37 : "1 d18",
      36 : "d18",
      35 : "1 d17",
      34 : "d17",
      33 : "1 d16",
      32 : "d16",
      31 : "1 d15",
      30 : "d15",
      29 : "13 d8",
      28 : "d14",
      27 : "11 d8",
      26 : "d13",
      25 : "9 d8",
      24 : "d12",
      23 : "7 d8",
      22 : "d11",
      21 : "13 d4",
      20 : "d10",
      19 : "11 d4",
      18 : "d9",
      17 : "9 d4",
      16 : "d8",
      15 : "7 d4",
      14 : "d7",
      13 : "5 d4",
      12 : "d6",
      11 : "3 d4",
      10 : "d5",
      9 : "1 d4",
      8 : "d4",
      7 : "3 d2",
      6 : "d3",
      5 : "1 d2",
      4 : "d2",
      3 : "1 d1",
      2 : "d1"
    }
    this.oneDartCheckouts = {
      50 : "d25",
      40 : "d20",
      38 : "d19",
      36 : "d18",
      34 : "d17",
      32 : "d16",
      30 : "d15",
      28 : "d14",
      26 : "d13",
      24 : "d12",
      22 : "d11",
      20 : "d10",
      18 : "d9",
      16 : "d8",
      14 : "d7",
      12 : "d6",
      10 : "d5",
      8 : "d4",
      6 : "d3",
      4 : "d2",
      2 : "d1"
    }
  }

  // todo: sieht irgendwie aus als könnte man das geiler machen :D
  getCheckoutDarts(remainingScore: number, throwsLeft: number): string | undefined {
    if (throwsLeft === 3) {
      return this.threeDartsCheckouts[remainingScore];
    } else if (throwsLeft === 2) {
      return this.twoDartsCheckouts[remainingScore];
    } else if (throwsLeft === 1) {
      return this.oneDartCheckouts[remainingScore];
    }

    return undefined;
  }


}
