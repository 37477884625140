export class IdGenerator {
    private id: number;

    constructor() {
        this.id = 1;
    }

    generateId(): number {
        return this.id++;
    }
}