import { Surface, Text } from "@react-native-material/core";
import { Component } from "react";
import { View } from "react-native";
import { styles } from "../../styles";
import { CricketResultPlayerCardProps } from "../../types";


export class CricketResultPlayerCard extends Component<CricketResultPlayerCardProps> {
    constructor(props: CricketResultPlayerCardProps) {
        super(props);
    }

    render() {
        return (
            <Surface elevation={4} key={`sur-${this.props.player.id}`} category="medium" style={styles.playerCard}>
                <View key={`sur-txt-${this.props.player.id}`}>
                    <View key={`li-first-row-${this.props.player.id}`} style={styles.playerCardRow}>
                        <Text style={styles.playerCardName}>
                            {this.props.player.name}
                        </Text>
                        <Text>
                            {this.props.ranking}. Platz
                        </Text>
                    </View>
                    <Text>
                        Punktzahl: <Text style={{fontWeight: "bold"}}>{this.props.player.score}</Text>
                    </Text>
                </View>
            </Surface>
        );
    }
}