import { Component } from "react";
import { Divider } from "@react-native-material/core";
import { styles } from "../styles";

export class FormDivider extends Component {
    render() {
        return (
            <Divider style={styles.formDivider}/>
        )
    }
}