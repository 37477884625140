import { Component } from "react";
import { ScrollView, View } from "react-native";
import { WinScreenProps, Player } from "../../types";
import { Button, Text } from "@react-native-material/core";
import { primaryColor, secondaryColor, styles } from "../../styles";
import { ScrollViewSpacer } from "../../components/ScrollViewSpacer";
import { ResultPlayerCard } from "../components/ResultPlayerCard";

export class WinScreen extends Component<WinScreenProps> {
    constructor(props: WinScreenProps) {
        super(props);
    }

    private compareTwoLosers(player1: Player, player2: Player): number {
        if (player1.wonSets < player2.wonSets) {
            return 1;
        }
        if (player1.wonSets > player2.wonSets) {
            return -1;
        }
    
        if (player1.overAllWonLegs < player2.overAllWonLegs) {
            return 1;
        }
        if (player1.overAllWonLegs > player2.overAllWonLegs) {
            return -1;
        }
    
        return 0;
    }

    render() {
        const rankedLosers = [...this.props.losers].sort(this.compareTwoLosers);
        const loserElements = rankedLosers.map((loser, loserIndex) =>  <ResultPlayerCard player={loser} ranking={loserIndex + 2}/>)

        return (
            <ScrollView style={styles.mainPage}>
                <View style={{alignSelf: "flex-end", marginBottom: 22}}>
                    <Button title="Einstellungen ⚙️" color={secondaryColor} onPress={this.props.unsetGameMode}/>
                </View>
                <Text style={styles.headLine1}>Ergebnisse 🏆</Text>
                <ResultPlayerCard player={this.props.winner} ranking={1}/>
                {loserElements}
                <Button title="Rematch 🔁" color={primaryColor} onPress={this.props.rematch} style={{margin: 20}}/>
                <ScrollViewSpacer/>
            </ScrollView>
        )
    }
}
