import { Component } from "react";
import { HitEntryProps } from "../../types";
import { styles } from "../../styles";
import { Pressable, View } from "react-native";
import { Text } from "@react-native-material/core";

export class HitEntry extends Component<HitEntryProps> {
    public constructor(props: HitEntryProps) {
        super(props);
    }

    render() {
        return (
            <Pressable
                key={`hit-${this.props.target}-${this.props.hits}`}
                onPress={() => this.props.hitTarget(this.props.target)}
                disabled={this.props.disabled}
                style={({pressed}) => [pressed ? styles.cricketButtonPressed : this.props.disabled ? styles.cricketButtonDisabled : styles.cricketButton]}>
                <View style={{flexDirection: "row"}}>
                    <Text style={{flex: 11}}>{this.props.target.toString()}</Text>
                    <Text style={{flex: 1, textAlign: "right", fontSize: 10, fontStyle: "italic"}}>{this.props.hits.toString()}</Text>
                </View>
            </Pressable>
        )
    }
}