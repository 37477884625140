import AsyncStorage from "@react-native-async-storage/async-storage";

export interface GlobalSettingsModel {
    customButtonsEnabled: boolean;
}

export class GlobalSettingsStorage {
    private static readonly globalSettingsKey = "globalSettings";

    /**
     * return null if not found or Error by AsyncStorage
     */
    public static async getGlobalSettingsSafe(): Promise<GlobalSettingsModel | null> {
        let globalSettingsRaw: string | null = null;

        try {
            globalSettingsRaw = await AsyncStorage.getItem(this.globalSettingsKey);
        } catch (error) {
            return null;
        }

        if(!globalSettingsRaw) {
            return null;
        }

        const globalSettings = JSON.parse(globalSettingsRaw!);

        return {
            customButtonsEnabled: globalSettings.customButtonsEnabled === true || globalSettings.customButtonsEnabled === "true"
        };
    }

    public static async areCustomButtonsEnabled(): Promise<boolean> {
        const globalSettings = await this.getGlobalSettingsSafe();
        return globalSettings?.customButtonsEnabled ?? false;
    }

    /**
     * return true for success, false for failure
     */
    public static async setCustomButtons(enabled: boolean): Promise<boolean> {
        const globalSettings = await this.getGlobalSettingsSafe();
        if(!globalSettings) {
            return  this.tryToSetGlobalSettings({
                customButtonsEnabled: enabled
            });
        }

        globalSettings.customButtonsEnabled = enabled;
        return this.tryToSetGlobalSettings(globalSettings);
    }

    /**
     * return true for success, false for failure
     */
    public static async tryToSetGlobalSettings(globalSettings: GlobalSettingsModel): Promise<boolean> {
        try {
            await AsyncStorage.setItem(this.globalSettingsKey, JSON.stringify(globalSettings));
            return true;
        } catch (error) {
            return false;
        }
    }
}