import { Component } from "react";
import { CricketBackButtonProps, HitEntryProps } from "../../types";
import { styles } from "../../styles";
import { Pressable, View } from "react-native";
import { Text } from "@react-native-material/core";

export class CricketBackButton extends Component<CricketBackButtonProps> {
    public constructor(props: CricketBackButtonProps) {
        super(props);
    }

    render() {
        return (
            <Pressable
                key={`back-${this.props.playerIndex}`}
                onPress={() => this.props.goBackOneHit()}
                disabled={this.props.disabled}
                style={({pressed}) => [pressed ? styles.cricketBackButtonPressed : this.props.disabled ? styles.cricketButtonDisabled : styles.cricketBackButton]}>
                    <Text >🔙</Text>
            </Pressable>
        )
    }
}