import { GlobalSettingsModel } from "./storage/GlobalSettingsStorage";

export type Checkout = {
    [key: number]: string;
}

export type InVariant = "Straight In" | "Double In" | "Triple In";

export type OutVariant = "Straight Out" | "Double Out" | "Triple Out" | "Master Out";

export type WinScreenProps = {
    key: string;
    unsetGameMode: () => Promise<void>;
    rematch: () => Promise<void>;
    losers: Player[];
    winner: Player;
}

export type LegResultPlayerCardProps = ResultPlayerCardProps & {
    legsToSet: number;
}

export type ResultPlayerCardProps = {
    player: Player;
    ranking: number;
}

export type CounterButtonProps = {
    throwDart: (buttonValue: number) => Promise<void>;
    buttonValue: number;
    disabled?: boolean;
}

export interface CustomThrowDefinition {
    value: number;
    scoreMode: 0 | 1 | 2;
}

export interface CustomButtonProps {
    throwDart: (value: number) => Promise<void>;
    changeMultiplier: (multiplier: 0 | 1 | 2) => Promise<void>;
    firstThrow: CustomThrowDefinition;
    secondThrow: CustomThrowDefinition;
    thirdThrow: CustomThrowDefinition;
    buttonLabel: string;
    disabled?: boolean;
}

export type MissButtonProps = {
    throwDart: (buttonValue: number) => Promise<void>;
    disabled?: boolean;
}

export type BackButtonProps = {
    goBack: () => Promise<void>;
    disabled?: boolean;
}

export type CounterState = {
    currentDart: number;
    currentScoreMode: number;
    currentRoundScore: number;
    thrownDarts: number[];
    loadedGlobalSettings?: GlobalSettingsModel;
}

export type CounterProps = {
    active: boolean;
    currentScore: number;
    startScore: number;
    outVariant: OutVariant;
    inVariant: InVariant;
    finishRoundForPlayer: (newCurrentScore: number, thrownDarts: number[], toastMessage?: string) => void;
    currentRound: number;
    goBackOnePlayer: () => Promise<void>;
}

export type CounterGroupProps = {
    initialPlayers: Player[];
    legsToSet: number;
    setsToWin: number;
    outVariant: OutVariant;
    inVariant: InVariant;
    finishGame: (winner: Player, losers: Player[]) => Promise<void>;
    unsetGameMode: () => Promise<void>;
}

export type CounterGroupState = {
    newPlayerName: string;
    newPlayerStartScore: number;
    gameActive: boolean;
    players: Player[];
    legWinner?: Player;
    finalWinner?: Player;
    lastStartPlayerIndex?: number;
}

export enum AvailableGameModes {
    X01,
    CRICKET
}

export type GameOptionsProps = {
    redirectToGlobalSettings: () => void;
    setX01GameMode: (legsToSet: number, setsToWin: number, outVariant: OutVariant, inVariant: InVariant) => Promise<void>;
    setCricketGameMode: () => Promise<void>;
}

export type GameOptionsState = {
    legsToSet: string;
    setsToWin: string;
    outVariant: OutVariant,
    inVariant: InVariant,
    gameModeSet: AvailableGameModes;
}

export interface GlobalSettingsState {
    customButtonsEnabled: boolean;
}

export interface GlobalSettingsProps {
    setGlobalSettingsToSaved: () => void;
    initialSettings: GlobalSettingsState;
}

export type GameAreaProps = {}

export interface X01 {
    legsToSet: number;
    setsToWin: number;
    outVariant: OutVariant;
    inVariant: InVariant;
    winner?: Player;
    losers?: Player[];
    initialPlayers : Player[];
}

export interface Cricket {
    winner?: CricketPlayer;
    losers?: CricketPlayer[];
    initialPlayers : CricketPlayer[];
}

export type GameAreaState = {
    x01?: X01;
    cricket?: Cricket;
    gameModeIsSet: boolean;
    globalSettingsSet: boolean;
    loadedGlobalSettings?: GlobalSettingsModel;
}

export interface RoundResult {
    // min 1 entry, max 3 entries
    thrownDarts: number[];
    // is not always the same as the sum of thrownDarts
    // e.g. 0 after it was over thrown
    totalScore: number;
}

export type PossibleHits = 15 | 16 | 17 | 18 | 19 | 20 | 25;

export type Player = {
    id: number;
    name: string;
    startScore: number;
    currentScore: number;
    isActive: boolean;
    currentRound: number;
    roundHistory: RoundResult[];
    wonSets: number;
    wonLegs: number;
    overAllWonLegs: number;
    legAverages: number[];
}

export interface CricketPlayer {
    id: number;
    name: string;
    isActive: boolean;
    currentRound: number;
    hits: {
        [key in PossibleHits]: number
    };
    score: number;
}

export interface CricketPlayerHit {
    playerId: number;
    hit: PossibleHits;
}

export type WonLegComponentProps = {
    players: Player[];
    endLeg: () => void;
    back: () => void;
    legsToSet: number;
}

export interface CricketGroupProps {
    initialPlayers: CricketPlayer[];
    unsetGameMode: () => Promise<void>;
    finishGame: (winner: CricketPlayer, losers: CricketPlayer[]) => Promise<void>;
}

export interface CricketGroupState {
    gameActive: boolean,
    newPlayerName: string,
    players: CricketPlayer[],
    winner?: CricketPlayer,
    lockedNumbers: {
        [key in PossibleHits]: boolean;
    },
    hitHistory: CricketPlayerHit[],
    lastStartPlayerIndex?: number;
}

export interface CricketCounterProps {}

export interface CricketCounterState {}

export interface HitEntryProps {
    target: PossibleHits;
    playerIndex: number;
    hits: number;
    hitTarget: (hit: PossibleHits) => void;
    disabled: boolean;
}

export interface CricketBackButtonProps {
    playerIndex: number;
    goBackOneHit: () => void;
    disabled: boolean;
}

export interface CricketWinScreenProps {
    unsetGameMode: () => Promise<void>;
    rematch: () => Promise<void>;
    losers: CricketPlayer[];
    winner: CricketPlayer;
}

export interface CricketResultPlayerCardProps {
    player: CricketPlayer;
    ranking: number;
}
