import { Component } from "react";
import { ScrollView, View } from "react-native";
import SelectDropdown from "react-native-select-dropdown";
import { AvailableGameModes, GameOptionsProps, GameOptionsState, InVariant, OutVariant } from "../types";
import { Button, TextInput, Text } from "@react-native-material/core";
import {primaryColor, secondaryColor, styles} from "../styles";
import { FormDivider } from "../components/FormDivider";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

const inVariants: InVariant[] = ["Straight In", "Double In", "Triple In"];
const outVariants: OutVariant[] = ["Straight Out", "Double Out", "Triple Out", "Master Out"];

export class GameOptions extends Component<GameOptionsProps, GameOptionsState> {
    constructor(props: GameOptionsProps) {
        super(props);
        this.state = {
            setsToWin: "1",
            legsToSet: "1",
            outVariant: "Double Out",
            inVariant: "Straight In",
            gameModeSet: AvailableGameModes.X01
        };   
    }

    setGameMode = async(): Promise<void> => {
        if(this.state.gameModeSet === AvailableGameModes.X01) {
            await this.props.setX01GameMode(parseInt(this.state.legsToSet), parseInt(this.state.setsToWin), this.state.outVariant, this.state.inVariant);
            return;
        }
        
        await this.props.setCricketGameMode();
        return;
    }

    render() {
        return (
            <ScrollView style={styles.mainPage}>

                <Button title="Globale Einstellungen 🔧️" variant="outlined" color="black"
                        style={{marginBottom: 22}} onPress={this.props.redirectToGlobalSettings}/>

                <Text style={styles.headLine1}>Wie soll gespielt werden? ⚙️</Text>

                <View style={styles.formWrapper}>
                    {/* @ts-ignore */}
                    <SelectDropdown 
                        data={["X01 🥇", "Cricket 🏏"]}
                        defaultValue={"X01 🥇"}
                        renderDropdownIcon={() => <Text>⬇</Text>}
                        buttonStyle={{
                            backgroundColor: "#eee",
                            borderRadius: 8,
                            width: "100%",
                            marginBottom: 20,
                        }}
                        onSelect={async (selectedItem, index): Promise<void> => {
                            await this.setState({gameModeSet: index === 0 ? AvailableGameModes.X01 : AvailableGameModes.CRICKET});
                        }}/>
                    {(this.state.gameModeSet === AvailableGameModes.X01) && (
                        <>
                            <FormDivider/>
                            <TextInput 
                            label="Legs zum Set"
                            onChangeText={(legsToSet) => {
                                const legsToSetStr = legsToSet.toString();
                                const onlyNumeric = legsToSetStr.replace(/[^0-9]/g, '');
                                this.setState({legsToSet: onlyNumeric});
                            }}  
                            // @ts-ignore
                            inputMode="numeric"
                            color={primaryColor}
                            keyboardType="numeric"
                            value={this.state.legsToSet}
                            />
                            <FormDivider/>
                            <TextInput 
                                label="Sets zum Sieg"
                                onChangeText={(setsToWin) => {
                                    const setsToWinStr = setsToWin.toString();
                                    const onlyNumeric = setsToWinStr.replace(/[^0-9]/g, '');
                            
                                    this.setState({setsToWin: onlyNumeric});
                                }}
                                // @ts-ignore
                                inputMode="numeric"
                                color={primaryColor}
                                keyboardType="numeric"
                                value={this.state.setsToWin}
                            />
                            <FormDivider/>
                            <Text style={styles.selectBoxLabel}>Variante</Text>
                            {/* @ts-ignore */}
                            <SelectDropdown 
                                data={outVariants} 
                                defaultValue={outVariants[1]}
                                renderDropdownIcon={() => <Text>⬇</Text>}
                                buttonStyle={{
                                    backgroundColor: "#eee",
                                    borderRadius: 8,
                                    width: "100%",
                                    marginBottom: 20,
                                }}
                                onSelect={(outVariant) => {
                                    this.setState({outVariant})
                                }}
                            />
                            <SelectDropdown 
                                data={inVariants} 
                                defaultValue={inVariants[0]}
                                renderDropdownIcon={() => <Text>⬇</Text>}
                                buttonStyle={{
                                    backgroundColor: "#eee",
                                    borderRadius: 8,
                                    width: "100%",
                                    marginBottom: 20,
                                }}
                                onSelect={(inVariant) => {
                                    this.setState({inVariant})
                                }}
                            />
                        </>
                    )}
                </View>
                <View style={styles.primaryButtonContainer}>
                    <Button 
                        title="Weiter"
                        color={primaryColor}
                        onPress={this.setGameMode} 
                        trailing={props => <Icon name="send" {...props} />}
                        style={styles.primaryButton}
                    />
                </View>
                <View style={{paddingBottom: 200}} />
            </ScrollView>
        )
    }
}