import { Surface, Text } from "@react-native-material/core";
import { Component } from "react";
import { View } from "react-native";
import { LegResultPlayerCardProps } from "../../types";
import { darkerSecondaryColor, styles } from "../../styles";
import { ScoreCalculator } from "../../helper/ScoreCalculator";


export class LegResultPlayerCard extends Component<LegResultPlayerCardProps> {
    private scoreCalculator: ScoreCalculator;
    private isWinner = false;

    constructor(props: LegResultPlayerCardProps) {
        super(props);
        this.scoreCalculator = new ScoreCalculator();
        if(this.props.ranking === 1) {
            this.isWinner = true;
        }
    }

    render() {
        const wonLegComponent = (this.isWinner && this.props.player.wonLegs + 1 < this.props.legsToSet) ?
            <Text style={{color: darkerSecondaryColor, fontWeight: "bold"}}>+1</Text> : 
            <></>;

        const wonSetComponent = (this.isWinner && this.props.player.wonLegs + 1 === this.props.legsToSet) ?
            <Text style={{color: darkerSecondaryColor, fontWeight: "bold"}}>+1</Text> :
            <></>;

        return (
            <Surface elevation={4} key={`sur-${this.props.player.id}`} category="medium" style={styles.playerCard}>
                <View key={`sur-txt-${this.props.player.id}`}>
                    <View key={`li-first-row-${this.props.player.id}`} style={styles.playerCardRow}>
                        <Text style={styles.playerCardName}>
                            {this.props.player.name}
                        </Text>
                        <Text>
                            {this.props.ranking}. Platz
                        </Text>
                    </View>
                    <Text>
                        Endstand: <Text style={{fontWeight: "bold"}}>{this.props.player.currentScore}</Text>
                    </Text>
                    <Text>
                        Average: <Text style={{fontWeight: "bold"}}>{this.scoreCalculator.getAverageOfCurrentLeg(this.props.player).toLocaleString("de")}</Text>
                    </Text>
                    <Text>
                        Gestartet bei: <Text style={{fontWeight: "bold"}}>{this.props.player.startScore}</Text> | Sets: <Text style={{fontWeight: "bold"}}>{this.props.player.wonSets}</Text>{wonSetComponent} | Legs: <Text style={{fontWeight: "bold"}}>{this.props.player.wonLegs}</Text>{wonLegComponent} 
                    </Text>
                </View>
            </Surface>
        );
    }
}