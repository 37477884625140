import { ReactElement, Component } from "react";
import { IdGenerator } from "../../helper/IdGenerator";
import { CricketGroupProps, CricketGroupState, CricketPlayer, PossibleHits } from "../../types";
import Toast from "react-native-root-toast";
import { PREFIX_LIST } from "../../helper/constants";
import { ScrollView, View } from "react-native";
import { ScrollViewSpacer } from "../../components/ScrollViewSpacer";
import { primaryColor, secondaryColor, styles } from "../../styles";
import {Button, Surface, Text, TextInput} from "@react-native-material/core";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { HitEntry } from "../components/HitEntry";
import { CricketBackButton } from "../components/BackButton";

export class CricketGroup extends Component<CricketGroupProps, CricketGroupState> {
    private prefixList: string[];
    private idGenerator: IdGenerator;

    constructor(props: CricketGroupProps) {
        super(props);
        this.idGenerator = new IdGenerator();

        this.state = {
            gameActive: false,
            newPlayerName: "",
            players: props.initialPlayers,
            winner: undefined,
            lockedNumbers: {
                15: false,
                16: false,
                17: false,
                18: false,
                19: false,
                20: false,
                25: false,
            },
            hitHistory: [],
        };

        this.prefixList = [...PREFIX_LIST];
    }

    addPlayer = async (): Promise<void> => {
        if(!this.state.newPlayerName) {
            return;
        }

        const fullName = `${this.getNamePrefix(this.state.newPlayerName)} ${this.state.newPlayerName.trim()}`;
        const newPlayer: CricketPlayer = {
            id: this.idGenerator.generateId(),
            name: fullName,
            isActive: false,
            currentRound: 1,
            hits: {
                15: 0,
                16: 0,
                17: 0,
                18: 0,
                19: 0,
                20: 0,
                25: 0,
            },
            score: 0,
        }
        const newPlayers = [...this.state.players, newPlayer];

        await this.setState({
            players: newPlayers,
            newPlayerName: ""
        });
    }

    lockHitIfNeccessary = async (hit: PossibleHits): Promise<void> => {
        const shouldBeLocked = this.state.players.every((player) => player.hits![hit] >= 3);

        if(!shouldBeLocked) {
            return;
        }

        await this.setState(({lockedNumbers}) => ({
            lockedNumbers: {
                ...lockedNumbers,
                [hit]: true,
            }
        }));
    }

    winMatchIfPossible = async (): Promise<void> => {
        const activePlayer = {...this.getActivePlayer()};
        const hasAllHits = Object.values(activePlayer.hits!).every((hit) => hit >= 3);
        const hasHighestScore = this.state.players.every((player) => player.score < activePlayer.score || player.id === activePlayer.id);

        if(!hasAllHits || !hasHighestScore) {
            return;
        }

        await this.setState(() => ({
            winner: activePlayer,
        }));

        await this.props.finishGame(this.state.winner!, this.state.players.filter((player) => player.id !== this.state.winner!.id));
    }


    hitTarget = async (hit: PossibleHits): Promise<void> => {
        const activePlayer = {...this.getActivePlayer()};
        const playerIndex = this.getActivePlayerIndex();

        if(this.state.lockedNumbers[hit]) {
            Toast.show("Diese Zahl ist bereits gesperrt!", {
                duration: Toast.durations.SHORT,
                shadow: true,
                hideOnPress: true,
            });
            return;
        }

        activePlayer.hits![hit] += 1;
        if(activePlayer.hits![hit] > 3) {
            activePlayer.score += hit;
        }
        const allPlayers = [...this.state.players];
        allPlayers[playerIndex] = activePlayer;

        await this.setState(({players, hitHistory}) => ({
            players: allPlayers,
            hitHistory: [...hitHistory, {playerId: activePlayer.id, hit}],
        }));

        await this.lockHitIfNeccessary(hit);
        await this.winMatchIfPossible();
    }

    setActivePlayer = async (playerIndex: number): Promise<void> => {
        const allPlayers = [...this.state.players];
        await this.setState(() => ({
            players: allPlayers.map((player, pIndex) => {
                if(pIndex === playerIndex){
                    return {...player, isActive: true};
                }

                return {...player, isActive: false};
            }),
        }));
    };

    setNextPlayerActive = async (): Promise<void> => {
        const nextActivePlayerIndex = this.getNextActivePlayerIndex();
        this.setActivePlayer(nextActivePlayerIndex);
    }

    getWinnerIndex = (): number => {
        return this.state.players.findIndex(player => player.id === this.state.winner?.id);
    }

    getPlayerIndex = (id: number): number => {
        return this.state.players.findIndex(player => player.id === id);
    }

    goBackOneHit = async () => {
        if(!this.state.hitHistory.length) {
            Toast.show("Es wurde noch kein Treffer eingetragen!", {
                duration: Toast.durations.SHORT,
                shadow: true,
                hideOnPress: true,
            });
            return;
        }

        const allPlayers = [...this.state.players].map((player) => ({...player, isActive: false}));

        const hitHistoryCopy = [...this.state.hitHistory];
        const lastHit = hitHistoryCopy.pop();
        const playerOfLastHit: CricketPlayer = {...allPlayers.find(player => player.id === lastHit!.playerId)!};
        playerOfLastHit.hits![lastHit!.hit] -= 1;
        playerOfLastHit.isActive = true;
        const playerIndex = this.getPlayerIndex(playerOfLastHit.id);
        allPlayers[playerIndex] = playerOfLastHit;

        this.setState(({players}) => ({
            players: allPlayers,
            winner: undefined,
            hitHistory: hitHistoryCopy,
        }));
    }

    getNextActivePlayerIndex = (): number => {
        const activePlayerIndex = this.getActivePlayerIndex();

        return activePlayerIndex + 1 >= this.state.players.length ? 0 : activePlayerIndex + 1;
    }

    getLastActivePlayerIndex = (): number => {
        const activePlayerIndex = this.getActivePlayerIndex();
        return activePlayerIndex - 1 < 0 ? this.state.players.length - 1 : activePlayerIndex - 1;
    }

    getActivePlayer = (): CricketPlayer => {
        return this.state.players.find(player => player.isActive)!;
    }

    getActivePlayerIndex = (): number => {
        return this.state.players.findIndex(player => player.isActive);
    }

    setStartingPlayer = async (): Promise<void> => {
        const players = [...this.state.players];
        if(this.state.lastStartPlayerIndex) {
            if(this.state.lastStartPlayerIndex + 1 >= this.state.players.length) {
                players[0].isActive = true;
            } else {
                players[this.state.lastStartPlayerIndex + 1].isActive = true;
            }
        } else {
            const randomPlayerIndex = Math.floor(Math.random() * this.state.players.length);
            players[randomPlayerIndex].isActive = true;
        }

        await this.setState({players});
        await this.setState({lastStartPlayerIndex: this.getActivePlayerIndex()});
    }

    removePlayer = async (playerIndex: number): Promise<void> => {
        if(!this.state.players.length) {
            return;
        }

        const playersAfterRemoval = [...this.state.players.slice(0, playerIndex), ...this.state.players.slice(playerIndex + 1)];

        await this.setState({
            players: playersAfterRemoval,
        })
    }

    unsetActivePlayer = async (): Promise<void> => {
        const allPlayers = [...this.state.players];

        await this.setState(() => ({
            players: allPlayers.map((player) => ({...player, isActive: false})),
        }));
    }

    mapCricketPlayersToInitialPlayers = (players: CricketPlayer[]): CricketPlayer[] => {
        return players.map((player) => {
            return {
                id: player.id,
                name: player.name,
                currentRound: 1,
                isActive: false,
                hits: {
                    15: 0,
                    16: 0,
                    17: 0,
                    18: 0,
                    19: 0,
                    20: 0,
                    25: 0,
                },
                score: 0,
            }
        });
    }

    endGame = async (): Promise<void> => {
        await this.unsetActivePlayer();

        await this.setState({
            gameActive: false,
            hitHistory: [],
            winner: undefined,
            lockedNumbers: {
                15: false,
                16: false,
                17: false,
                18: false,
                19: false,
                20: false,
                25: false,
            },            
            players: this.mapCricketPlayersToInitialPlayers(this.state.players),
        });
    }

    startGame = async(): Promise<void> => {
        if(this.state.players.length < 2) {
            Toast.show("Es müssen mindestens zwei Spieler hinzugefügt werden!", {
                duration: Toast.durations.SHORT,
                shadow: true,
                hideOnPress: true,
            });
            return;
        }

        await this.setStartingPlayer();
        await this.setState({gameActive: true});
    };

    getNamePrefix(name: string) {
        if(name === "Maren") {
            return "❤️";
        }

        const chosenPrefixIndex = Math.floor(Math.random() * this.prefixList.length);
        return this.prefixList.splice(chosenPrefixIndex, 1);
    }

    render() {
        if(this.state.gameActive) {
            let playerComponents: ReactElement[] = [];
            const activePlayerIndex = this.getActivePlayerIndex();
            const activePlayer = this.getActivePlayer();

            const activePlayerComponent = (
                <View key={`wrapper-${activePlayerIndex}`}>
                    <View style={{flexDirection:'row', marginTop: 8, marginBottom: 8}} key={"text-"+activePlayerIndex}>
                        <Text key={"headline-"+activePlayerIndex} style={{...styles.listPlayerName, fontSize: 19}}>{activePlayer.name}</Text>
                        <Text key={"score-"+activePlayerIndex} style={{...styles.listPlayerScore, fontSize: 19}}>{activePlayer.score}</Text>
                    </View>
                    <View style={{flexDirection: "row"}}>
                        <HitEntry key={`hit-20-${activePlayerIndex}`} hits={activePlayer.hits[20]} target={20} playerIndex={activePlayerIndex} hitTarget={this.hitTarget} disabled={this.state.lockedNumbers[20]}/>
                        <HitEntry key={`hit-19-${activePlayerIndex}`} hits={activePlayer.hits[19]} target={19} playerIndex={activePlayerIndex} hitTarget={this.hitTarget} disabled={this.state.lockedNumbers[19]}/>
                    </View>
                    <View style={{flexDirection: "row"}}>
                        <HitEntry key={`hit-18-${activePlayerIndex}`} hits={activePlayer.hits[18]} target={18} playerIndex={activePlayerIndex} hitTarget={this.hitTarget} disabled={this.state.lockedNumbers[18]}/>
                        <HitEntry key={`hit-17-${activePlayerIndex}`} hits={activePlayer.hits[17]} target={17} playerIndex={activePlayerIndex} hitTarget={this.hitTarget} disabled={this.state.lockedNumbers[17]}/>
                    </View>
                    <View style={{flexDirection: "row"}}>
                        <HitEntry key={`hit-16-${activePlayerIndex}`} hits={activePlayer.hits[16]} target={16} playerIndex={activePlayerIndex} hitTarget={this.hitTarget} disabled={this.state.lockedNumbers[16]}/>
                        <HitEntry key={`hit-15-${activePlayerIndex}`} hits={activePlayer.hits[15]} target={15} playerIndex={activePlayerIndex} hitTarget={this.hitTarget} disabled={this.state.lockedNumbers[15]}/>
                    </View>
                    <View style={{flexDirection: "row"}}>
                        <CricketBackButton playerIndex={activePlayerIndex} goBackOneHit={this.goBackOneHit} disabled={this.state.hitHistory.length < 1}/>
                        <HitEntry key={`hit-25-${activePlayerIndex}`} hits={activePlayer.hits[25]} target={25} playerIndex={activePlayerIndex} hitTarget={this.hitTarget} disabled={this.state.lockedNumbers[25]}/>
                    </View>
                </View>
            );

            this.state.players.forEach((player, playerIndex) => {
                if(player.isActive) {
                    return;
                }

                playerComponents.push(
                    <View style={{flexDirection:'row', marginTop: 8, marginBottom: 8}} key={"text-"+playerIndex}>
                        <Text key={"headline-"+playerIndex} style={styles.listPlayerName}>{player.name}</Text>
                        <Text key={"score-"+playerIndex} style={styles.listPlayerScore}>{player.score}</Text>
                    </View>
                );
            })
            
            const endGameButton = <Button title="Spiel abbrechen" color="error" onPress={this.endGame}/>;

            return (
                <ScrollView style={styles.mainPage}>
                    <View style={styles.primaryButtonContainer}>
                        {endGameButton}
                    </View>
                    <View style={{marginBottom: 25}}/>
                    {playerComponents}
                    {activePlayerComponent}
                    <View style={{marginTop: 50}}/>
                    <View style={styles.primaryButtonContainer}>
                        <Button 
                            title="Nächster Spieler"
                            color={secondaryColor}
                            onPress={this.setNextPlayerActive} 
                            trailing={props => <Icon name="send" {...props} />}
                            style={styles.primaryButton}
                        />
                    </View>
                    <ScrollViewSpacer/>
                </ScrollView>
            )
        }

        let playerComponents: ReactElement[] = [];

        this.state.players.forEach((player, playerIndex) => {
            playerComponents.push(
                <Surface elevation={4} key={`li-${playerIndex}`} category="medium" style={styles.playerCard}>
                    <View key={`li-txt-${playerIndex}`}>
                        <View key={`li-first-row-${playerIndex}`} style={styles.playerCardRow}>
                            <Text style={styles.playerCardName}>
                                {player.name}
                            </Text>
                            <Button 
                                key={`delete-${playerIndex}`} 
                                onPress={() => this.removePlayer(playerIndex)}
                                title="Entfernen"
                                variant="text"
                                compact={true}
                                color="error">
                            </Button>
                        </View>
                    </View>
                </Surface>
            );
        })

        const playerHeadline = this.state.players.length > 0 ? <Text style={styles.headLine1}>Eingetragene Spieler:</Text> : <></>;
        const primaryButton = this.state.players.length > 0 ? 
            <View style={styles.primaryButtonContainer}>
                <Button 
                        title="Spiel starten"
                        color={primaryColor}
                        onPress={this.startGame}
                        trailing={props => <Icon name="send" {...props} />}
                        style={styles.primaryButton}
                    />
            </View>
            : <></>;
        
        return (
            <ScrollView style={styles.mainPage}>
                <View style={{alignSelf: "flex-end", marginBottom: 22}}>
                    <Button title="Zurück ⚙️" color="error" onPress={this.props.unsetGameMode}/>
                </View>
                <Text style={styles.headLine1}>Wer spielt mit? 👤</Text>
                <View style={styles.formWrapper}>
                    <TextInput 
                        onChangeText={(newPlayerName) => {
                            this.setState({newPlayerName});
                        }}
                        value={this.state.newPlayerName}
                        label="Name"
                        color={primaryColor}
                        style={{marginBottom: 14}}
                    />
                    <Button title="Spieler hinzufügen" color={secondaryColor} onPress={this.addPlayer} />
                </View>
                <View style={{marginBottom: 30}} />
                <View>
                    {playerHeadline}
                    <View>
                        {playerComponents}
                    </View>
                </View>
                {primaryButton}
                <ScrollViewSpacer/>
            </ScrollView>
        )
    }
}
