import { Component } from "react";
import { Pressable, Vibration } from "react-native";
import { styles } from "../../styles";
import { BackButtonProps, CounterButtonProps, CustomButtonProps, MissButtonProps } from "../../types";
import { Text } from "@react-native-material/core";

export class CounterButton extends Component<CounterButtonProps> {
    constructor(props: CounterButtonProps) {
        super(props);
        this.handleClick.bind(this);
      }
        
      handleClick = () => {
        Vibration.vibrate();

        this.props.throwDart(this.props.buttonValue);
      }

    render() {
        if(this.props.disabled) {
            return (
                <Pressable style={styles.counterButtonDisabled} disabled={true}>
                    <Text>{this.props.buttonValue}</Text>
                </Pressable>
            )
        }

        return (
            <Pressable 
              style={({pressed}) => [pressed ? styles.counterButtonPressed : styles.counterButton]} 
              onPress={this.handleClick}>
                <Text>{this.props.buttonValue}</Text>
            </Pressable>
        )
    }
}

export class MissButton extends Component<MissButtonProps> {
    constructor(props: MissButtonProps) {
        super(props);
    }

    handleClick = () => {
        Vibration.vibrate();

        this.props.throwDart(0);
    }
    render() {
        if(this.props.disabled) {
            return (
                <Pressable style={styles.counterButtonDisabled} disabled={true}>
                    <Text>Miss</Text>
                </Pressable>
            )
        }

        return (
            <Pressable 
              style={({pressed}) => [pressed ? styles.missButtonPressed : styles.missButton]}
              onPress={this.handleClick}>
                <Text>Miss</Text>
            </Pressable>
        )
    }
}

export class BackButton extends Component<BackButtonProps> {
    render() {
        if(this.props.disabled) {
            return (
                <Pressable style={styles.backButtonDisabled} disabled={true}>
                    <Text >🔙</Text>
                </Pressable>
            )
        }

        return (
            <Pressable 
              style={({pressed}) => [pressed ? styles.backButtonPressed : styles.backButton]} 
              onPress={this.props.goBack}>
                <Text>🔙</Text>
            </Pressable>
        )
    }
}

export class CustomButton extends Component<CustomButtonProps> {
    private handleClick = async (): Promise<void> => {
        Vibration.vibrate();

        await this.props.changeMultiplier(this.props.firstThrow.scoreMode);
        await this.props.throwDart(this.props.firstThrow.value);
        await this.props.changeMultiplier(this.props.secondThrow.scoreMode);
        await this.props.throwDart(this.props.secondThrow.value);
        await this.props.changeMultiplier(this.props.thirdThrow.scoreMode);
        await this.props.throwDart(this.props.thirdThrow.value);
    }

    render() {
        if(this.props.disabled) {
            return (
                <Pressable style={styles.customButtonDisabled} disabled={true}>
                    <Text>{this.props.buttonLabel}</Text>
                </Pressable>
            )
        }

        return (
            <Pressable
              style={({pressed}) => [pressed ? styles.customButtonPressed : styles.customButton]}
              onPress={this.handleClick}>
                <Text>{this.props.buttonLabel}</Text>
            </Pressable>
        )
    }
}