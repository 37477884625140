import { Surface, Text } from "@react-native-material/core";
import { Component } from "react";
import { View } from "react-native";
import { ResultPlayerCardProps } from "../../types";
import { styles } from "../../styles";
import { ScoreCalculator } from "../../helper/ScoreCalculator";


export class ResultPlayerCard extends Component<ResultPlayerCardProps> {
    private scoreCalculator: ScoreCalculator;

    constructor(props: ResultPlayerCardProps) {
        super(props);
        this.scoreCalculator = new ScoreCalculator();
    }

    render() {
        return (
            <Surface elevation={4} key={`sur-${this.props.player.id}`} category="medium" style={styles.playerCard}>
                <View key={`sur-txt-${this.props.player.id}`}>
                    <View key={`li-first-row-${this.props.player.id}`} style={styles.playerCardRow}>
                        <Text style={styles.playerCardName}>
                            {this.props.player.name}
                        </Text>
                        <Text>
                            {this.props.ranking}. Platz
                        </Text>
                    </View>
                    <Text>
                        Average Gesamt: <Text style={{fontWeight: "bold"}}>{this.scoreCalculator.getOverAllAverage(this.props.player).toLocaleString("de")}</Text>
                    </Text>
                    <Text>
                        Gestartet bei: <Text style={{fontWeight: "bold"}}>{this.props.player.startScore}</Text> | Sets: <Text style={{fontWeight: "bold"}}>{this.props.player.wonSets}</Text> | Legs: <Text style={{fontWeight: "bold"}}>{this.props.player.overAllWonLegs}</Text> 
                    </Text>
                </View>
            </Surface>
        );
    }
}