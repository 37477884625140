import { Component } from "react";
import { Player, WonLegComponentProps } from "../../types";
import { View } from "react-native";
import { errorColor, primaryColor, styles } from "../../styles";
import { LegResultPlayerCard } from "./LegResultPlayerCard";
import { Button, Text } from "@react-native-material/core";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";

export class WonLegComponent extends Component<WonLegComponentProps> {
    constructor(props: WonLegComponentProps) {
        super(props);
    }

    private compareTwoPlayers(player1: Player, player2: Player): number {
        if (player1.currentScore > player2.currentScore) {
            return 1;
        }
        if (player1.currentScore < player2.currentScore) {
            return -1;
        }
    
        return 0;
    }

    render() {
        const rankedPlayers = [...this.props.players].sort(this.compareTwoPlayers);
        const playerElements = rankedPlayers.map((player, playerIndex) => <LegResultPlayerCard key={`card-${playerIndex}`} player={player} ranking={playerIndex + 1} legsToSet={this.props.legsToSet}/>)
        const winner = rankedPlayers[0];
        const subHeadline = (winner.wonLegs + 1 === this.props.legsToSet) ? 
            <Text style={styles.headLine2}>Set gewonnen!</Text> :
        (winner.wonLegs + 2 === this.props.legsToSet) ? 
            <Text style={styles.headLine2}>Noch 1 Leg zum Set!</Text> :
            <Text style={styles.headLine2}>Noch {this.props.legsToSet - (winner.wonLegs + 1)} Legs zum Set!</Text>;

        return (
            <>
                <Text style={styles.headLine1}>Glückwunsch {winner.name} 🎉</Text>
                {subHeadline}
                {playerElements}
                <View style={{flexDirection: "row", justifyContent: "space-between", marginTop: 11}}>
                    <View>
                        <Button 
                            title="Zurück"
                            color={errorColor}
                            onPress={() => this.props.back()} 
                            style={{width: 140}}
                        />
                    </View>
                    <View style={{alignSelf: "flex-end"}}>
                        <Button 
                            title="Weiter"
                            color={primaryColor}
                            onPress={() => this.props.endLeg()} 
                            trailing={props => <Icon name="send" {...props} />}
                            style={{width: 180}}
                        />
                    </View>
                </View>
            </>
        )
    }
}
