import { StatusBar } from 'expo-status-bar';
import { View } from 'react-native';
import { CounterGroup } from '../x01/pages/CounterGroup';
import { GameOptions } from './GameOptions';
import { WinScreen } from '../x01/pages/WinScreen';    
import { ReactElement, Component } from "react";
import { CricketPlayer, GameAreaProps, GameAreaState, Player, InVariant, OutVariant } from "../types";
import { CricketGroup } from '../cricket/pages/CricketGroup';
import { CricketWinScreen } from '../cricket/pages/WinScreen';
import { GlobalSettings } from "./GlobalSettings";
import { GlobalSettingsStorage } from "../storage/GlobalSettingsStorage";

export class GameArea extends Component<GameAreaProps, GameAreaState> {
    constructor(props: GameAreaProps) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = (): GameAreaState => ({
        x01: {
            legsToSet: 1,
            setsToWin: 1,
            outVariant: "Double Out",
            inVariant: "Straight In",
            winner: undefined,
            losers: undefined,
            initialPlayers: [],
        },
        cricket: undefined,
        gameModeIsSet: false,
        globalSettingsSet: true,
    })


    mapPlayersToInitialPlayers = (players: Player[]): Player[] => {
        return players.map((player) => {
            return {
                id: player.id,
                name: player.name,
                wonLegs: 0,
                wonSets: 0,
                currentScore: player.startScore,
                overAllWonLegs: 0,
                currentRound: 0,
                hadNoScoreLastRound: false,
                isActive: false,
                roundHistory: [],
                startScore: player.startScore,
                legAverages: []
            }
        });
    }

    mapCricketPlayersToInitialPlayers = (players: CricketPlayer[]): CricketPlayer[] => {
        return players.map((player) => {
            return {
                id: player.id,
                name: player.name,
                currentRound: 1,
                isActive: false,
                hits: {
                    15: 0,
                    16: 0,
                    17: 0,
                    18: 0,
                    19: 0,
                    20: 0,
                    25: 0,
                },
                score: 0,
            }
        });
    }

    setX01GameMode = async (legsToSet: number, setsToWin: number, outVariant: OutVariant, inVariant: InVariant, overWriteInitialPlayers?: Player[]) => {
        await this.setState(({x01}) => ({
            x01: {
                initialPlayers: overWriteInitialPlayers?.length ? overWriteInitialPlayers : [],
                losers: undefined,
                winner: undefined,
                legsToSet,
                setsToWin,
                outVariant,
                inVariant,
            },
            gameModeIsSet: true,
            cricket: undefined,
        }));
    }

    setCricketGameMode = async (overwriteInitialPlayers?: CricketPlayer[]) => {
        await this.setState(({cricket}) => ({
            x01: undefined,
            gameModeIsSet: true,
            cricket: {
                initialPlayers: overwriteInitialPlayers ? overwriteInitialPlayers : cricket?.initialPlayers?.length ? cricket?.initialPlayers : [],
                losers: undefined,
                winner: undefined,
            },
        }));
    };

    unsetGameMode = async () => {
        if(this.state.x01) {
            const players = this.state.x01.winner ? [this.state.x01.winner, ...(this.state.x01.losers ?? [])] : [];
            await this.setState(({x01}) => ({
                gameModeIsSet: false,
                x01: {
                    ...x01!,
                    winner: undefined,
                    losers: undefined,
                    initialPlayers: this.mapPlayersToInitialPlayers(players)
                },
                cricket: undefined,
            }));
            return;
        }

        if(this.state.cricket) {
            const players = this.state.cricket.winner ? [this.state.cricket.winner, ...(this.state.cricket.losers ?? [])] : [];
            await this.setState({
                gameModeIsSet: false,
                cricket: {
                    winner: undefined,
                    losers: undefined,
                    initialPlayers: this.mapCricketPlayersToInitialPlayers(players),
                },
                x01: undefined,
            });
            return;
        }

        await this.setState(this.getInitialState());
        await this.loadGlobalSettings();
    }

    rematch = async () => {
        if(this.state.x01) {
            await this.setX01GameMode(
                this.state.x01.legsToSet,
                this.state.x01.setsToWin,
                this.state.x01.outVariant,
                this.state.x01.inVariant,
                this.mapPlayersToInitialPlayers([this.state.x01.winner!, ...(this.state.x01.losers ?? [])])
            );
            return;
        }

        if(this.state.cricket) {
            await this.setCricketGameMode(this.mapCricketPlayersToInitialPlayers([this.state.cricket.winner!, ...(this.state.cricket.losers ?? [])]));
            return;
        }

        await this.setState(this.getInitialState());
        await this.loadGlobalSettings();
    }

    finishX01Game = async (winner: Player, losers: Player[] = []) => {
        await this.setState(({x01}) => ({x01: {...x01!, winner, losers}}));
    }

    finishCricketGame = async (winner: CricketPlayer, losers: CricketPlayer[] = []) => {
        await this.setState(({cricket}) => ({cricket: {...cricket!, winner, losers}}));
    }

    private async loadGlobalSettings(): Promise<void> {
        const settings = await GlobalSettingsStorage.getGlobalSettingsSafe();
        this.setState({loadedGlobalSettings: !!settings ? settings : undefined});
    }

    public async componentDidMount(): Promise<void> {
        await this.loadGlobalSettings();
    }

    private async setGlobalSettingsToSaved(): Promise<void> {
        await this.loadGlobalSettings();
        this.setState({globalSettingsSet: true});
    }

    getGameAreaContent = (): ReactElement => {
        if(!this.state.globalSettingsSet) {
            return (
                <GlobalSettings setGlobalSettingsToSaved={this.setGlobalSettingsToSaved}
                    initialSettings={this.state.loadedGlobalSettings ? this.state.loadedGlobalSettings : {customButtonsEnabled: false}}/>
            )
        }

        if(this.state.gameModeIsSet) {
            if(this.state.x01 && this.state.x01.winner) {
                return (
                    <WinScreen key="WinScreen" 
                        unsetGameMode={this.unsetGameMode}
                        rematch={this.rematch}
                        losers={this.state.x01.losers ?? []}
                        winner={this.state.x01.winner}/>
                )
            } else if(this.state.x01) {
                return (
                    <CounterGroup key="CounterGroup"
                        initialPlayers={this.state.x01?.initialPlayers ?? []}
                        unsetGameMode={this.unsetGameMode}
                        finishGame={this.finishX01Game}
                        legsToSet={this.state.x01?.legsToSet ?? 1}
                        outVariant={this.state.x01?.outVariant ?? "Double Out"}
                        inVariant={this.state.x01?.inVariant ?? "Straight In"}
                        setsToWin={this.state.x01?.setsToWin ?? 1}/>
                )
            } else if(this.state.cricket && this.state.cricket.winner) {
                return (
                    <CricketWinScreen 
                        losers={this.state.cricket.losers!} 
                        winner={this.state.cricket.winner!}
                        rematch={this.rematch}
                        unsetGameMode={this.unsetGameMode}/>
                )
            } else {
                return (
                    <CricketGroup key="CricketGroup"
                        initialPlayers={this.state.cricket?.initialPlayers ?? []}
                        finishGame={this.finishCricketGame}
                        unsetGameMode={this.unsetGameMode}/>
                )
            }
        }

        return <GameOptions key="GameOptions"
                    setX01GameMode={this.setX01GameMode} setCricketGameMode={this.setCricketGameMode}
                    redirectToGlobalSettings={() => this.setState({globalSettingsSet: false})}/>;
    }


    render() {
        const content = this.getGameAreaContent();

        return (
            <View>
                {content}
                <StatusBar style="auto" />
            </View>
        )
    }
}
