import { Component } from "react";
import { View } from "react-native";
import {BackButton, CounterButton, CustomButton, MissButton} from './CounterButton';
import Toast from 'react-native-root-toast';
import { OutCalculator } from "../helper/OutCalculator";
import { CounterProps, CounterState } from "../../types";
import { lightText, pressedBackground, primaryColor, styles } from "../../styles";
import SegmentedControl from '@react-native-segmented-control/segmented-control';
import { Text } from "@react-native-material/core";
import { GlobalSettingsStorage } from "../../storage/GlobalSettingsStorage";

export class Counter extends Component<CounterProps, CounterState> {
    private outCalculator: OutCalculator;

    constructor(props: CounterProps) {
        super(props);
        this.state = {
            currentDart: 0,
            currentScoreMode: 0,
            currentRoundScore: 0,
            thrownDarts: [],
        };
        this.throwDart.bind(this);

        this.outCalculator = new OutCalculator();
    }

    async componentDidMount(): Promise<void> {
        await this.loadGlobalSettings();
    }

    private async loadGlobalSettings(): Promise<void> {
        const settings = await GlobalSettingsStorage.getGlobalSettingsSafe();
        this.setState({loadedGlobalSettings: !!settings ? settings : undefined});
    }

    changeScoreMode = async(mode: number): Promise<void> => {
        await this.setState({currentScoreMode: mode});
    }

    throwDart = async(score: number): Promise<void> => {
        if (!this.hasOpeningDartAlreadyBeenThrown()) {
            if (!this.isOpenedCorrectWay(score)) {
                score = 0;
            }
        }

        const multipliedScoreByMode = score * (this.state.currentScoreMode + 1); 

        if(multipliedScoreByMode > 60 || !this.props.active) {
            return;
        }

        const newCurrentScore = this.props.currentScore - (this.state.currentRoundScore + multipliedScoreByMode);

        if (await this.isOverThrown(newCurrentScore, multipliedScoreByMode)) {
            await this.manageOverThrown(multipliedScoreByMode);
            return;
        }

        const thrownDarts = [...this.state.thrownDarts, multipliedScoreByMode];
        await this.setState(({currentDart, currentRoundScore}) => ({
            currentDart: currentDart + 1,
            currentScoreMode: 0,
            currentRoundScore: currentRoundScore + multipliedScoreByMode,
            thrownDarts,
        }));

        if(this.state.currentDart < 3 && newCurrentScore > 0) {
            return;
        }

        await this.backToFirstDart();

        await this.props.finishRoundForPlayer(newCurrentScore, thrownDarts);
    }

    isOpenedCorrectWay = (score: number): boolean => {
        switch (this.props.inVariant) {
            case "Double In":
                return this.state.currentScoreMode === 1 || score === 50;
            case "Triple In":
                return this.state.currentScoreMode === 2;
            default:
                return true;
        }
    }

    hasOpeningDartAlreadyBeenThrown = (): boolean => {
        if (this.props.startScore !== this.props.currentScore) {
            return true;
        }
        return (this.state.thrownDarts.some((thrownDart) => thrownDart > 0 ))
    }

    isOverThrown = async (remainingScore: number, thrownScore: number): Promise<boolean> => {
        const outVariant = this.props.outVariant;

        if (remainingScore < 0) return true;
        if (outVariant === "Straight Out") return false;
        if (remainingScore > 0) {
            if (remainingScore === 1) return true;
            if (outVariant === "Triple Out" && remainingScore === 2) return true;

            return false;
        }

        // If the player has thrown 50 points (bullseye), it will be counted as a double
        if(thrownScore === 50) {
            await this.setState({currentScoreMode: 1});
        }

        switch (outVariant) {
            case "Double Out":
                return this.state.currentScoreMode !== 1;
            case "Triple Out":
                return this.state.currentScoreMode !== 2;
            case "Master Out":
                return this.state.currentScoreMode === 0;
        }
    }

    manageOverThrown = async(score: number): Promise<void> => {
        const thrownDarts = [...this.state.thrownDarts, score];

        await this.backToFirstDart();

        await this.props.finishRoundForPlayer(this.props.currentScore, thrownDarts, "Überworfen!");
    }

    backToFirstDart = async(): Promise<void> => {
        await this.setState(() => ({
            currentDart: 0,
            currentRoundScore: 0,
            thrownDarts: [],
            currentScoreMode: 0,
        }));
    }

    goBack = async(): Promise<void> => {
        if(this.state.currentDart === 0 || !this.props.active || !this.state.thrownDarts.length) {
            await this.props.goBackOnePlayer();

            return;
        }

        const thrownDarts = [...this.state.thrownDarts];
        const lastDart = thrownDarts.pop();
        const oldCurrentRoundScore = this.state.currentRoundScore - lastDart!;
        this.setState(({currentDart}) => ({
            currentDart: currentDart - 1,
            currentRoundScore: oldCurrentRoundScore,
            thrownDarts,
        }));

        Toast.show("Der Wurf mit "+ lastDart +" Punkten wurde rückgängig gemacht!", {
            duration: Toast.durations.SHORT,
            shadow: true,
            hideOnPress: true,
        });
    }

    render() {
        const customButtons = this.state.loadedGlobalSettings?.customButtonsEnabled ?
            <View style={{flexDirection: "row", justifyContent: "space-around"}}>
                <CustomButton buttonLabel="26" throwDart={this.throwDart} changeMultiplier={this.changeScoreMode}
                              disabled={!this.props.active || this.state.currentDart > 0} firstThrow={{scoreMode: 0, value: 20}}
                              secondThrow={{scoreMode: 0, value: 5}} thirdThrow={{scoreMode: 0, value: 1}}/>
                <CustomButton buttonLabel="60" throwDart={this.throwDart} changeMultiplier={this.changeScoreMode}
                              disabled={!this.props.active || this.state.currentDart > 0} firstThrow={{scoreMode: 0, value: 20}}
                              secondThrow={{scoreMode: 0, value: 20}} thirdThrow={{scoreMode: 0, value: 20}}/>
                <CustomButton buttonLabel="3X0" throwDart={this.throwDart} changeMultiplier={this.changeScoreMode}
                              disabled={!this.props.active || this.state.currentDart > 0} firstThrow={{scoreMode: 0, value: 0}}
                              secondThrow={{scoreMode: 0, value: 0}} thirdThrow={{scoreMode: 0, value: 0}}/>
                <CustomButton buttonLabel="180" throwDart={this.throwDart} changeMultiplier={this.changeScoreMode}
                              disabled={!this.props.active || this.state.currentDart > 0} firstThrow={{scoreMode: 2, value: 20}}
                              secondThrow={{scoreMode: 2, value: 20}} thirdThrow={{scoreMode: 2, value: 20}}/>
            </View> : null;

        return (
            <View>
                <Text style={{...styles.headLine2, textAlign:"center"}}>
                    {this.props.currentScore - this.state.currentRoundScore}
                </Text>
                <SegmentedControl
                    values={["Single", "Double", "Triple"]}
                    selectedIndex={this.state.currentScoreMode}
                    onChange={(event) => {
                        this.changeScoreMode(event.nativeEvent.selectedSegmentIndex);
                    }}
                    style={{marginBottom: 20, height: 40}}
                    tintColor={primaryColor}
                    backgroundColor={pressedBackground}
                />
                <View style={{flexDirection: "row", marginBottom: 20}}>
                    <Text style={{flex: 3, color: lightText}}>
                        Runde Nr. {this.props.currentRound}
                    </Text>
                    <View>
                        <Text style={{flex: 3, color: lightText}}>
                            {this.state.thrownDarts[0] ?? "0"}/{this.state.thrownDarts[1] ?? "0"}/{this.state.thrownDarts[2] ?? "0"}
                        </Text>
                    </View>
                </View>
                <View style={{minHeight: 30}}>
                    <Text style={{color: lightText, alignSelf: "center"}}>
                        {this.outCalculator.getCheckoutDarts(this.props.currentScore - this.state.currentRoundScore, 3 - this.state.currentDart)}
                    </Text>
                </View>
                <View style={{flexDirection: "row", justifyContent: "space-around"}}> 
                    <CounterButton buttonValue={1} throwDart={this.throwDart} disabled={!this.props.active}/>
                    <CounterButton buttonValue={2} throwDart={this.throwDart} disabled={!this.props.active}/>
                    <CounterButton buttonValue={3} throwDart={this.throwDart} disabled={!this.props.active}/>
                    <CounterButton buttonValue={4} throwDart={this.throwDart} disabled={!this.props.active}/>
                    <CounterButton buttonValue={5} throwDart={this.throwDart} disabled={!this.props.active}/>
                </View>
                <View style={{flexDirection: "row", justifyContent: "space-around"}}> 
                    <CounterButton buttonValue={6} throwDart={this.throwDart} disabled={!this.props.active}/>
                    <CounterButton buttonValue={7} throwDart={this.throwDart} disabled={!this.props.active}/>
                    <CounterButton buttonValue={8} throwDart={this.throwDart} disabled={!this.props.active}/>
                    <CounterButton buttonValue={9} throwDart={this.throwDart} disabled={!this.props.active}/>
                    <CounterButton buttonValue={10} throwDart={this.throwDart} disabled={!this.props.active}/>
                </View>
                <View style={{flexDirection: "row", justifyContent: "space-around"}}> 
                    <CounterButton buttonValue={11} throwDart={this.throwDart} disabled={!this.props.active}/>
                    <CounterButton buttonValue={12} throwDart={this.throwDart} disabled={!this.props.active}/>
                    <CounterButton buttonValue={13} throwDart={this.throwDart} disabled={!this.props.active}/>
                    <CounterButton buttonValue={14} throwDart={this.throwDart} disabled={!this.props.active}/>
                    <CounterButton buttonValue={15} throwDart={this.throwDart} disabled={!this.props.active}/>
                </View>
                <View style={{flexDirection: "row", justifyContent: "space-around"}}> 
                    <CounterButton buttonValue={16} throwDart={this.throwDart} disabled={!this.props.active}/>
                    <CounterButton buttonValue={17} throwDart={this.throwDart} disabled={!this.props.active}/>
                    <CounterButton buttonValue={18} throwDart={this.throwDart} disabled={!this.props.active}/>
                    <CounterButton buttonValue={19} throwDart={this.throwDart} disabled={!this.props.active}/>
                    <CounterButton buttonValue={20} throwDart={this.throwDart} disabled={!this.props.active}/>
                </View>
                {customButtons}
                <View style={{flexDirection: "row", justifyContent: "space-around"}}>
                    <BackButton goBack={this.goBack}/>
                    <MissButton throwDart={this.throwDart} disabled={!this.props.active}/>
                    <CounterButton buttonValue={25} disabled={this.state.currentScoreMode === 2 || !this.props.active} throwDart={this.throwDart}/>
                    <CounterButton buttonValue={50} disabled={this.state.currentScoreMode === 1 || this.state.currentScoreMode === 2 || !this.props.active} throwDart={this.throwDart}/>
                </View>
            </View>
        )
    }
}
