import { Component } from "react";
import {primaryColor, styles} from "../styles";
import { ScrollView, View } from "react-native";
import { Button, ListItem, Switch, Text } from "@react-native-material/core";
import { GlobalSettingsProps, GlobalSettingsState } from "../types";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import Toast from "react-native-root-toast";
import {GlobalSettingsStorage} from "../storage/GlobalSettingsStorage";

export class GlobalSettings extends Component<GlobalSettingsProps, GlobalSettingsState> {
    constructor(props: GlobalSettingsProps) {
        super(props);

        this.state = {
            customButtonsEnabled: this.props.initialSettings.customButtonsEnabled,
        }
    }

    private saveSettings = async (): Promise<void> => {
        const success = await GlobalSettingsStorage.setCustomButtons(this.state.customButtonsEnabled);

        if (success) {
            Toast.show("Einstellungen gespeichert", {
                duration: Toast.durations.SHORT,
                shadow: true,
                hideOnPress: true,
            })
        } else {
            Toast.show("Einstellungen konnten nicht gespeichert werden", {
                duration: Toast.durations.SHORT,
                shadow: true,
                hideOnPress: true,
            })
        }

        this.props.setGlobalSettingsToSaved();
    }

    render() {
        return(
            <ScrollView style={styles.mainPage}>
                <View style={{...styles.primaryButtonContainer, marginBottom: 22}}>
                    <Button title="Zurück ⚙️" color="error" onPress={this.props.setGlobalSettingsToSaved}/>
                </View>
                <Text style={styles.headLine1}>Globale Einstellungen 🔧</Text>
                <View style={styles.formWrapper}>
                    <ListItem
                        title="Aktiviere Shortcut Buttons"
                        trailing={
                            <Switch value={this.state.customButtonsEnabled}
                                    onValueChange={() => this.setState({customButtonsEnabled: !this.state.customButtonsEnabled})}/>
                        }
                        onPress={() => this.setState({customButtonsEnabled: !this.state.customButtonsEnabled})}
                        secondaryText="Nur für X01 verfügbar"
                    />
                </View>
                <View style={styles.primaryButtonContainer}>
                    <Button
                        title="Speichern"
                        color={primaryColor}
                        onPress={this.saveSettings}
                        trailing={props => <Icon name="floppy" {...props} />}
                        style={styles.primaryButton}
                        disabled={this.state.customButtonsEnabled === this.props.initialSettings.customButtonsEnabled}
                    />
                </View>
            </ScrollView>
        );
    }
}