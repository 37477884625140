import { RootSiblingParent } from 'react-native-root-siblings';
import { GameArea } from './src/pages/GameArea';
import { AppBar } from "@react-native-material/core";
import { primaryColor } from './src/styles';

export default function App() {
  return (
    <RootSiblingParent>
      <AppBar style={{paddingTop: 18}} color={primaryColor} title="Darten am Donnerstag 🎯"/>
      <GameArea />
    </RootSiblingParent>
  );
}