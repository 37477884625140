import { Component, ReactElement } from "react";
import { ScrollView, View} from "react-native";
import SelectDropdown from "react-native-select-dropdown";
import Toast from 'react-native-root-toast';
import { Counter } from "../components/Counter";
import { IdGenerator } from "../../helper/IdGenerator";
import { CounterGroupProps, CounterGroupState, Player } from "../../types";
import { Button, Text, TextInput, Surface } from "@react-native-material/core";
import { primaryColor, secondaryColor, styles } from "../../styles";
import { FormDivider } from "../../components/FormDivider";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { ScrollViewSpacer } from "../../components/ScrollViewSpacer";
import { WonLegComponent } from "../components/WonLegComponent";
import { ScoreCalculator } from "../../helper/ScoreCalculator";
import { PREFIX_LIST } from "../../helper/constants";

const possibleStartScores = [301, 501, 701, 1001]

export class CounterGroup extends Component<CounterGroupProps, CounterGroupState> {
    private prefixList: string[];
    private idGenerator: IdGenerator;
    private scoreCalculator: ScoreCalculator;

    constructor(props: CounterGroupProps) {
        super(props);
        this.idGenerator = new IdGenerator();
        this.scoreCalculator = new ScoreCalculator();

        this.state = {
            gameActive: false,
            newPlayerStartScore: 301,
            newPlayerName: "",
            players: props.initialPlayers,
            legWinner: undefined,
            finalWinner: undefined,            
            lastStartPlayerIndex: undefined,
        };

        this.prefixList = [...PREFIX_LIST];
    }

    changeNewPlayerStartScore = async(score: number) => {
        await this.setState({newPlayerStartScore: score});
    }

    addPlayer = async () => {
        if(!this.state.newPlayerName) {
            return;
        }

        const fullName = `${this.getNamePrefix(this.state.newPlayerName)} ${this.state.newPlayerName.trim()}`;
        const newPlayer: Player = {
            id: this.idGenerator.generateId(),
            name: fullName,
            startScore: this.state.newPlayerStartScore,
            currentScore: this.state.newPlayerStartScore,
            isActive: !this.getActivePlayer(),
            currentRound: 1,
            roundHistory: [],
            wonSets: 0,
            wonLegs: 0,
            overAllWonLegs: 0,
            legAverages: [],
        }
        const newPlayers = [...this.state.players, newPlayer];

        await this.setState({
            players: newPlayers,
            newPlayerName: ""
        });
    }

    getLegWinnerIndex = (): number => {
        return this.state.players.findIndex(player => player.id === this.state.legWinner?.id);
    }

    goBackOnePlayer = async () => {
        if(this.state.players.length === 1) {
            await this.handleGoBackForSinglePlayer();
            return;
        }

        await this.handleGoBackForMultiplayer();
    }

    handleGoBackForMultiplayer = async () => {
        const currentActivePlayerIndex = this.getActivePlayerIndex();
        const lastActivePlayerIndex = this.getLastActivePlayerIndex();
        const lastActivePlayer = this.state.players[lastActivePlayerIndex];
        if(!lastActivePlayer.roundHistory.length) {
            Toast.show("Es wurde noch kein Wurf eingegeben", {
                duration: Toast.durations.SHORT,
                shadow: true,
                hideOnPress: true,
            });
            return;
        }

        const sumOfLastThrows = lastActivePlayer.roundHistory[lastActivePlayer.roundHistory.length - 1].totalScore;
        const oldCurrentScore = lastActivePlayer.currentScore + sumOfLastThrows;

        const players = [...this.state.players];
        players[lastActivePlayerIndex].isActive = true;
        players[lastActivePlayerIndex].currentScore = oldCurrentScore;
        players[lastActivePlayerIndex].currentRound = lastActivePlayer.currentRound - 1;
        players[lastActivePlayerIndex].roundHistory.pop();
        players[currentActivePlayerIndex].isActive = false;

        Toast.show("Die letzte Runde von "+ lastActivePlayer.name +" wurde rückgängig gemacht. "+ lastActivePlayer.name +" ist wieder dran.", {
            duration: Toast.durations.SHORT,
            shadow: true,
            hideOnPress: true,
        });

        await this.setState({players, legWinner: undefined});
    }

    handleGoBackForSinglePlayer = async () => {
        const players = [...this.state.players];
        if(!players[0].roundHistory.length) {
            Toast.show("Es wurde noch kein Wurf eingegeben", {
                duration: Toast.durations.SHORT,
                shadow: true,
                hideOnPress: true,
            });
            return;
        }

        const sumOfLastThrows = players[0].roundHistory[players[0].roundHistory.length - 1].totalScore;
        const oldCurrentScore = players[0].currentScore + sumOfLastThrows;

        players[0].currentScore = oldCurrentScore;
        players[0].currentRound = players[0].currentRound - 1;
        players[0].roundHistory.pop();

        Toast.show("Die letzte Runde wurde rückgängig gemacht!", {
            duration: Toast.durations.SHORT,
            shadow: true,
            hideOnPress: true,
        });
        await this.setState({players, legWinner: undefined});
    }

    calculateScoreOfThrownDarts = (thrownDarts: number[]) => {
        return thrownDarts.reduce((sum: number, dart: number) => sum + dart, 0);
    }

    getNextActivePlayerIndex = () => {
        const activePlayerIndex = this.getActivePlayerIndex();
        return activePlayerIndex + 1 >= this.state.players.length ? 0 : activePlayerIndex + 1;
    }

    getLastActivePlayerIndex = () => {
        const activePlayerIndex = this.getActivePlayerIndex();
        return activePlayerIndex - 1 < 0 ? this.state.players.length - 1 : activePlayerIndex - 1;
    }

    getActivePlayer = (): Player => {
        return this.state.players.find(player => player.isActive)!;
    }

    getActivePlayerIndex = () => {
        return this.state.players.findIndex(player => player.isActive);
    }

    setStartingPlayer = async () => {
        const players = [...this.state.players];
        if(this.state.lastStartPlayerIndex) {
            if(this.state.lastStartPlayerIndex + 1 >= this.state.players.length) {
                players[0].isActive = true;
            } else {
                players[this.state.lastStartPlayerIndex + 1].isActive = true;
            }
        } else {
            const randomPlayerIndex = Math.floor(Math.random() * this.state.players.length);
            players[randomPlayerIndex].isActive = true;
        }

        await this.setState({players});
        await this.setState({lastStartPlayerIndex: this.getActivePlayerIndex()});
    }

    finishRoundForPlayer = async (newCurrentScore: number, thrownDarts: number[], toastMessage?: string) => {
        const activePlayerIndex = this.getActivePlayerIndex();
        const nextPlayerIndex = this.getNextActivePlayerIndex();
        const activePlayer = this.getActivePlayer();
        const legWinner = (newCurrentScore === 0) ? activePlayer : undefined;

        const players = [...this.state.players];
        const scoreDiff = players[activePlayerIndex].currentScore - newCurrentScore;
 
        players[activePlayerIndex].currentScore = newCurrentScore;
        players[activePlayerIndex].isActive = false;
        players[activePlayerIndex].currentRound++;
        players[activePlayerIndex].roundHistory.push({thrownDarts, totalScore: scoreDiff});

        players[nextPlayerIndex].isActive = true;

        await this.setState({players, legWinner});

        if(toastMessage) {
            Toast.show(toastMessage, {
                duration: Toast.durations.SHORT,
                shadow: true,
                hideOnPress: true,
            });
        }
    }

    removePlayer = async (playerIndex: number) => {
        if(!this.state.players.length) {
            return;
        }

        const playersAfterRemoval = [...this.state.players.slice(0, playerIndex), ...this.state.players.slice(playerIndex + 1)];

        await this.setState({
            players: playersAfterRemoval,
        })
    }

    startGame = async() => {
        if(!this.state.players.length) {
            Toast.show("Es muss mindestens ein Spieler hinzugefügt werden", {
                duration: Toast.durations.SHORT,
                shadow: true,
                hideOnPress: true,
            });
            return;
        }

        await this.setStartingPlayer();
        await this.setState({gameActive: true});
    };

    endLeg = async() => {
        const players = [...this.state.players];

        if(this.state.legWinner) {
            const legWinnerIndex = this.getLegWinnerIndex();
            const legWinner = this.state.legWinner;

            legWinner.wonLegs = legWinner.wonLegs += 1;
            legWinner.overAllWonLegs = legWinner.overAllWonLegs += 1;

            if(legWinner.wonLegs === this.props.legsToSet) {
                legWinner.wonSets = legWinner.wonSets += 1;
                legWinner.wonLegs = 0;

                players.forEach(player => {
                    player.wonLegs = 0;
                });

                if(legWinner.wonSets === this.props.setsToWin) {
                    await this.setState({finalWinner: {...legWinner, legAverages: [...legWinner.legAverages, this.scoreCalculator.getAverageOfCurrentLeg(legWinner)]}});
                    players.forEach(player => {
                        player.legAverages = [...player.legAverages, this.scoreCalculator.getAverageOfCurrentLeg(player)];
                    });

                    const losers = [...[...players].slice(0, legWinnerIndex), ...[...players].slice(legWinnerIndex + 1)];
                    await this.props.finishGame(this.state.finalWinner!, losers);
                    return;
                }
            }

            players[legWinnerIndex] = legWinner;
        }

        players.forEach(player => {
            player.legAverages = [...player.legAverages, this.scoreCalculator.getAverageOfCurrentLeg(player)];
            player.currentRound = 1;
            player.currentScore = player.startScore;
            player.roundHistory = [];
            player.isActive = false;
        });

        await this.setState({gameActive: false, players, legWinner: undefined});
    };

    getNamePrefix(name: string) {
        if(name === "Maren") {
            return "❤️";
        }

        const chosenPrefixIndex = Math.floor(Math.random() * this.prefixList.length);
        return this.prefixList.splice(chosenPrefixIndex, 1);
    }

    didPlayerWin = (player: Player) => {
        return this.state.legWinner && this.state.legWinner.id === player.id;
    }

    getDartCounterHeadline = () => {
        if(!this.state.gameActive) {
            return;
        }

        const activePlayer = this.getActivePlayer();
        if(!this.didPlayerWin(activePlayer)) {
            return <Text style={styles.headLine1}>{activePlayer.name}</Text>;
        }

        return <></>;
    };


    getDartCounter = () => {
        if(!this.state.gameActive) {
            return;
        }

        const activePlayer = this.getActivePlayer();
        if(!this.didPlayerWin(activePlayer)) {
            return <Counter 
                currentRound={activePlayer.currentRound}
                currentScore={activePlayer.currentScore}
                startScore={activePlayer.startScore}
                finishRoundForPlayer={this.finishRoundForPlayer}
                goBackOnePlayer={this.goBackOnePlayer}
                active={!this.state.legWinner}
                outVariant={this.props.outVariant}
                inVariant={this.props.inVariant}
            />;
        }

        return <></>;
    };

    render() {
        if(this.state.legWinner) {
            return (
                <ScrollView style={styles.mainPage}>
                    <WonLegComponent endLeg={this.endLeg} players={this.state.players} back={this.goBackOnePlayer} legsToSet={this.props.legsToSet}/>
                    <ScrollViewSpacer/>
                </ScrollView>
            );
        }

        if(this.state.gameActive) {
            let playerComponents: ReactElement[] = [];

            this.state.players.forEach((player, playerIndex) => {
                if(player.isActive) {
                    return;
                }

                playerComponents.push(
                    <View style={{flexDirection:'row', marginTop: 8, marginBottom: 8}} key={"text-"+playerIndex}>
                        <Text key={"headline-"+playerIndex} style={styles.listPlayerName}>{player.name}</Text>
                        <Text key={"score-"+playerIndex} style={styles.listPlayerScore}>{player.currentScore}</Text>
                    </View>
                );
            })

            const endGameButton = this.state.legWinner ?
                <Button 
                    title="Spiel abschließen"
                    leading={props => <Icon name="trophy" {...props} />}
                    style={styles.primaryButton} 
                    color={primaryColor}
                    onPress={this.endLeg}/> :
                <Button title="Spiel abbrechen" color="error" onPress={this.endLeg}/>;

            const counterHeadline = this.getDartCounterHeadline();
            const counter = this.getDartCounter();

            return (
                <ScrollView style={styles.mainPage}>
                    <View style={styles.primaryButtonContainer}>
                        {endGameButton}
                    </View>
                    <View style={{marginBottom: 25}}/>
    
                    {playerComponents}
                    {counterHeadline}
                    {counter}
                    <ScrollViewSpacer/>
                </ScrollView>
            )
        }

        let playerComponents: ReactElement[] = [];

        this.state.players.forEach((player, playerIndex) => {
            playerComponents.push(
                <Surface elevation={4} key={`li-${playerIndex}`} category="medium" style={styles.playerCard}>
                    <View key={`li-txt-${playerIndex}`}>
                        <View key={`li-first-row-${playerIndex}`} style={styles.playerCardRow}>
                            <Text style={styles.playerCardName}>
                                {player.name}
                            </Text>
                            <Button 
                                key={`delete-${playerIndex}`} 
                                onPress={() => this.removePlayer(playerIndex)}
                                title="Entfernen"
                                variant="text"
                                compact={true}
                                color="error">
                            </Button>
                        </View>
                        <Text>
                            Startet bei: <Text style={{fontWeight: "bold"}}>{player.startScore}</Text> | Sets: <Text style={{fontWeight: "bold"}}>{player.wonSets}</Text> | Legs: <Text style={{fontWeight: "bold"}}>{player.wonLegs}</Text>
                        </Text>
                    </View>
                </Surface>
            );
        })

        const playerHeadline = this.state.players.length > 0 ? <Text style={styles.headLine1}>Eingetragene Spieler:</Text> : <></>;
        const startButton = this.state.players.length > 0 ? 
            <View style={styles.primaryButtonContainer}>
                <Button 
                        title="Spiel starten"
                        color={primaryColor}
                        onPress={this.startGame}
                        trailing={props => <Icon name="send" {...props} />}
                        style={styles.primaryButton}
                    />
            </View>
            : <></>;
        
        return (
            <ScrollView style={styles.mainPage}>
                <View style={{alignSelf: "flex-end", marginBottom: 22}}>
                    <Button title="Zurück ⚙️" color="error" onPress={this.props.unsetGameMode}/>
                </View>
                <Text style={styles.headLine1}>Wer spielt mit? 👤</Text>
                <View style={styles.formWrapper}>
                    <TextInput 
                        onChangeText={(newPlayerName) => {
                            this.setState({newPlayerName});
                        }}
                        value={this.state.newPlayerName}
                        label="Name"
                        color={primaryColor}
                    />
                    <FormDivider />
                    <Text style={styles.selectBoxLabel}>Start bei</Text>
                    {/* @ts-ignore */}
                    <SelectDropdown 
                        data={possibleStartScores} 
                        defaultValue={this.state.newPlayerStartScore}
                        renderDropdownIcon={() => <Text>⬇</Text>}
                        buttonStyle={{
                            backgroundColor: "#eee",
                            borderRadius: 8,
                            width: "100%",
                            marginBottom: 20,
                        }}
                        onSelect={(selectedScore) => {
                            this.changeNewPlayerStartScore(selectedScore);
                        }}/>
                    <Button title="Spieler hinzufügen" color={secondaryColor} onPress={this.addPlayer} />
                </View>
                <View style={{marginBottom: 30}} />
                <View>
                    {playerHeadline}
                    <View>
                        {playerComponents}
                    </View>
                </View>
                {startButton}
                <ScrollViewSpacer/>
            </ScrollView>
        )
    }
}
