import { Player } from "../types";

export class ScoreCalculator 
{
    public getTotalLegScore(player: Player): number {
        return player.startScore - player.currentScore;
    }

    // https://www.reddit.com/r/Darts/comments/7wxdzc/how_are_averages_calculated_when_you_overthrow/
    public getAverageOfCurrentLeg(player: Player): number {
        const totalLegScore = this.getTotalLegScore(player);
        const numberOfRounds = player.roundHistory.length;

        if(numberOfRounds === 0) {
            return 0;
        }
        
        return parseFloat((totalLegScore / numberOfRounds).toFixed(2));
    }

    public getOverAllAverage(player: Player): number {
        if(player.legAverages.length === 0) {
            return 0;
        }

        const sumAllAverages = player.legAverages.reduce((a, b) => a + b, 0);

        return parseFloat((sumAllAverages / player.legAverages.length).toFixed(2));
    }
}